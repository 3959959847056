import React, {Fragment, useState} from 'react'
import {userStatus} from '../../../../utils/helpers'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useDispatch, useSelector} from 'react-redux'
import Modal from '../../../Modal/Modal'
import ChatService from '../../../../services/chatService'
import './ChatHeader.scss'

const ChatHeader = ({chat}) => {

    const dispatch = useDispatch();

    const [showChatOptions, setShowChatOptions] = useState(false);
    const [showAddFriendModal, setShowAddFriendModal] = useState(false);
    const [showRenameGroupModal, setShowRenameGroupModal] = useState(false);
    const [showLeaveChatModal, setShowLeaveChatModal] = useState(false);
    const [showDeleteChatModal, setShowDeleteChatModal] = useState(false);
    const [newGroupName, setNewGroupName] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const socket = useSelector(state => state.chatReducer.socket);

    const searchFriends = (e) => {
        ChatService.searchUsers(e.target.value)
            .then(res => setSuggestions(res))
    };

    const addNewFriend = (id) => {
        ChatService.addFriendToGroupChat(id, chat.id)
            .then(data => {
                socket.emit('add-user-to-group', data);
                setShowAddFriendModal(false)
            }).catch(err => console.log(err))
    };

    const leaveChat = () => {
        ChatService.leaveCurrentChat(chat.id)
            .then(data => {
                socket.emit('leave-current-chat', data)
            })
            .catch(err => console.log(err))
    };

    const renameGroup = () => {
        ChatService.renameGroup(chat.id, newGroupName)
            .then(data => {
                socket.emit('rename-group', data);
                setShowRenameGroupModal(false)
            })
            .catch(err => console.log(err))
    };

    const deleteChat = () => {
        ChatService.deleteCurrentChat(chat.id)
            .then(data => {
                socket.emit('delete-chat', data)
            })
            .catch(err => console.log(err))
    };

    return (
        <Fragment>
            <div id='chatter'>
                {
                    chat.Users.map(user => {
                        return <div className='chatter-info' key={user.id}>
                            <h3>{user.firstName} {user.lastName}</h3>
                            <div className='chatter-status'>
                                <span className={`online-status ${userStatus(user)}`}> </span>
                            </div>
                        </div>
                    })
                }
            </div>
            <FontAwesomeIcon
                onClick={() => setShowChatOptions(!showChatOptions)}
                icon={['fas', 'ellipsis-v']}
                className='fa-icon'/>
            {
                showChatOptions
                    ? <div id='settings'>

                        <div onClick={() => setShowAddFriendModal(true)}>
                            <FontAwesomeIcon
                                icon={['fas', 'user-plus']}
                                className='fa-icon'
                            />
                            <p>Add Member</p>
                        </div>
                        {
                            chat.type === 'group'
                                ? <div onClick={() => setShowRenameGroupModal(!showRenameGroupModal)}>
                                    <FontAwesomeIcon
                                        icon={['fas', 'user-edit']}
                                        className='fa-icon'
                                    />
                                    <p>Rename Group</p>
                                </div>
                                : null
                        }
                        {
                            chat.type === 'group'
                                ? <div onClick={() => leaveChat()}>
                                    <FontAwesomeIcon
                                        icon={['fas', 'sign-out-alt']}
                                        className='fa-icon'
                                    />
                                    <p>Exit Group</p>
                                </div>
                                : null
                        }
                        {
                            chat.type === 'dual' ?
                                <div onClick={() => deleteChat()}>
                                    <FontAwesomeIcon
                                        icon={['fas', 'trash']}
                                        className='fa-icon'
                                    />
                                    <p>Delete Chat</p>
                                </div>
                                : null
                        }
                    </div>
                    : null
            }
            {
                showAddFriendModal &&
                <Modal click={() => setShowAddFriendModal(false)}>

                    <Fragment key='header'>
                        <h3 className='m-0'>Add New Participant To Chat</h3>
                    </Fragment>

                    <Fragment key='body'>
                        <p className='mb-1'>Find Participants by typing their name</p>
                        <input onInput={e => searchFriends(e)} type='text' placeholder='Search...'/>
                        <div id='suggestions'>
                            {
                                suggestions.map(user => {
                                    return <div key={user.id} className='suggestion'>
                                        <p className='m-0'>{user.firstName} {user.lastName}</p>
                                        <button onClick={() => addNewFriend(user.id)}>Add</button>
                                    </div>
                                })
                            }
                        </div>
                    </Fragment>
                </Modal>
            }
            {
                showRenameGroupModal &&
                <Modal click={() => setShowRenameGroupModal(false)}>

                    <Fragment key='header'>
                        <h3 className='m-0'>Rename Group</h3>
                    </Fragment>

                    <Fragment key='body'>
                        <p className='mb-1'>Rename Group</p>
                        <input onChange={e => setNewGroupName(e.target.value)} type='text'
                               placeholder='New Group Name...'/>
                    </Fragment>

                    <Fragment key='footer'>
                        <button style={{backgroundColor: 'green'}} onClick={() => renameGroup()}>Change</button>
                    </Fragment>

                </Modal>
            }
        </Fragment>
    )
};

export default ChatHeader