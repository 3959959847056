import React, {useState, Fragment} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Friend from '../Friend/Friend'
import {setCurrentChat} from '../../../../store/actions/chat'
import Modal from '../../../Modal/Modal'
import ChatService from '../../../../services/chatService'
import './FriendList.scss'

const FriendList = () => {

    const dispatch = useDispatch();
    const chats = useSelector(state => state.chatReducer.chats);
    const socket = useSelector(state => state.chatReducer.socket);

    const [showFriendsModal, setShowFriendsModal] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [groupName, setGroupName] = useState("");

    const openChat = (chat) => {
        dispatch(setCurrentChat(chat))
    };

    const searchFriends = (e) => {
        ChatService.searchUsers(e.target.value)
            .then(res => setSuggestions(res))
    };

    const addNewFriend = (id, groupName) => {
        ChatService.createChat(id, groupName)
            .then(chats => {
                socket.emit('add-friend', chats);
                setGroupName("");
                setShowFriendsModal(false);
            }).catch(err => console.log(err))
    };

    return (
        <div id='friends' className='shadow-light'>
            <div id='title'>
                <h1 className='m-0'>New Chat</h1>
                <button onClick={() => setShowFriendsModal(true)}>Add</button>
            </div>

            <hr/>

            <div id='friends-box'>
                {
                    chats.length > 0
                        ? chats.map(chat => {
                            return <Friend click={() => openChat(chat)} chat={chat} key={chat.id}/>
                        })
                        : <p id='no-chat'>No friends added</p>
                }
            </div>

            {
                showFriendsModal &&
                <Modal click={() => setShowFriendsModal(false)}>
                    <Fragment key='header'>
                        <h3 className='m-0'>Create New Chat / Group</h3>
                    </Fragment>

                    <Fragment key='body'>
                        <p className='mb-1'>Group Name (Optional)</p>
                        <input onChange={e => setGroupName(e.target.value)} value={groupName} type='text'
                               placeholder='Group name'/>
                        <p className='mb-1'>Find Friends by Name</p>
                        <input onInput={e => searchFriends(e)} type='text' placeholder='Search...'/>
                        <div id='suggestions'>
                            {
                                suggestions.map(user => {
                                    return <div key={user.id} className='suggestion'>
                                        <p className='m-0'>{user.firstName} {user.lastName}</p>
                                        <button onClick={() => addNewFriend(user.id, groupName)}>Add</button>
                                    </div>
                                })
                            }
                        </div>
                    </Fragment>
                </Modal>
            }
        </div>
    )
};

export default FriendList