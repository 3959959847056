import React from 'react'
import {useSelector} from 'react-redux'
import {userStatus} from '../../../../utils/helpers'
import './Friend.scss'

const Friend = ({chat, click}) => {

    const groupIcon = process.env.REACT_APP_BACKEND_URL+'/group.svg';

    const currentChat = useSelector(state => state.chatReducer.currentChat);

    const isChatOpened = () => {
        return currentChat.id === chat.id ? 'opened' : ''
    };

    const lastMessage = () => {
        if (chat.Messages.length === 0) return '';

        const message = chat.Messages[chat.Messages.length - 1];
        return message.type === 'image' ? 'image uploaded' : message.message
    };

    const ifGroupChangeName = () => {
        return chat.type === 'group' ? chat.chatName : chat.Users[0].firstName +' '+ chat.Users[0].lastName
    };

    const ifGroupChangeIcon = () => {
        return chat.type === 'group' ? groupIcon : chat.Users[0].avatar
    };

    return (
        <div onClick={click} className={`friend-list ${isChatOpened()}`}>
            <div>
                <img width='40' height='40' src={ifGroupChangeIcon()} alt='User avatar'/>
                <div className='friend-info'>
                    <h4 className='m-0'>{ifGroupChangeName()}</h4>
                    <h5 className='m-0'>{lastMessage()}</h5>
                </div>
            </div>
            <div className='friend-status'>
                <span className={`online-status ${userStatus(chat.Users[0])}`}> </span>
            </div>
        </div>
    )
};

export default Friend