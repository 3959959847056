import React from 'react'
import {useSelector} from 'react-redux'
import ChatHeader from '../ChatHeader/ChatHeader'
import MessageBox from '../MessageBox/MessageBox'
import MessageInput from '../MessageInput/MessageInput'
import './Messenger.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import connecting_teams from '../../../../assets/images/connecting_teams.svg'

const Messenger = () => {

    const chat = useSelector(state => state.chatReducer.currentChat);

    const activeChat = () => {
        return Object.keys(chat).length > 0
    };

    return (
        <div id='messenger' className='shadow-light'>
            {
                activeChat()
                    ? <div id='messenger-wrap'>
                        <ChatHeader chat={chat}/>
                        <hr/>
                        <MessageBox chat={chat}/>
                        <MessageInput chat={chat}/>
                    </div>
                    : <div id='noActiveChat-div'>
                        <img src={connecting_teams} className="d-inline-block align-top" alt="SparrowChat"/>
                        <p><FontAwesomeIcon icon='sad-tear' className='fa-icon'/> Opps, No active chat !!!</p>
                    </div>
            }
        </div>
    )
};

export default Messenger