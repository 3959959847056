import React, {useState, useEffect} from 'react'
import loginImage from '../../assets/images/login.svg'
import sparrow from '../../assets/images/favicon.ico'
import {Link} from 'react-router-dom'
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux'
import {login} from '../../store/actions/auth'

import './Auth.scss'

const Login = ({history}) => {

    const {user} = useParams();

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');


    useEffect(() => {
        // Update the document title using the browser API
        if (user !== undefined && user !== ' ') {
            setEmail(user);
            setPassword(user);
            if (email !== '' && password !== '') {
                dispatch(login({email, password}, history));
            }
        }
    }, [email, password]);


    const submitForm = (e) => {
        e.preventDefault();
        dispatch(login({email, password}, history))
    };

    return (
        <div id='auth-container'>
            <div id='auth-card'>
                <div className='card-shadow'>
                    <div id='image-section'>
                        <img src={loginImage} alt='Login'/>
                    </div>

                    <div id='form-section'>

                        <h2>Welcome To Sparrow</h2>

                        <img src={sparrow} alt='Sparrow'/>

                        <form onSubmit={submitForm}>

                            <div className='input-field mb-1'>
                                <input
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                    required='required'
                                    type='text'
                                    placeholder='Registered Email Id / Mobile No.'/>
                            </div>

                            <div className='input-field mb-2'>
                                <input
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    required='required'
                                    type='password'
                                    placeholder='Password'/>
                            </div>

                            <button>LOGIN</button>

                        </form>

                        <p>Don't have an account? <Link to='/register'>Register</Link></p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login