import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux'

const ProtectedRoute = ({component: Component, ...props}) => {

    const isLoggedIn = useSelector(state => state.authReducer.isLoggedIn);

    let searchData = props.location.search;
    let user = '';
    if (searchData !== undefined)
        user = searchData.split('=')[1];

    let loginURL = '/login';
    if (user !== undefined && user !== '' && !isLoggedIn && user !== 'login' && user !== 'register') {
        loginURL = '/login/' + user;
    }

    console.log('Protected Final URL -> ', loginURL);

    return (
        <Route {...props}
               render={(props) => (
                   isLoggedIn
                       ? <Component {...props} />
                       : <Redirect to={loginURL}/>
               )}
        />
    );
};

export default ProtectedRoute